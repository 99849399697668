import React from "react";
import BursaryApplication from "../components/BursaryApplication";
import AutoLogout from "./AutoLogout";

export default function BursaryAutLogout() {
  return (
    <AutoLogout>
      <BursaryApplication />
    </AutoLogout>
  );
}
