"use client";

import { Footer } from "flowbite-react";

export function FooterComponents(props) {
  return (
    <Footer container className="bg-transparent">
      <div className="w-full text-center pl-5 pr-3">
        {props.login === "true" ? (
          <div className=""></div>
        ) : (
          <div className="w-full justify-between sm:flex sm:items-center xl:pl-96 sm:justify-between">
            <Footer.LinkGroup>
              <Footer.Link href="#">About</Footer.Link>
              <Footer.Link href="#">Privacy Policy</Footer.Link>
              <Footer.Link href="#">Licensing</Footer.Link>
              <Footer.Link href="#">Contact</Footer.Link>
            </Footer.LinkGroup>
          </div>
        )}
        <Footer.Divider />
        <Footer.Copyright
          href="#"
          by=" HEC. Version 1"
          year={1900 + new Date().getYear()}
        />
      </div>
    </Footer>
  );
}
