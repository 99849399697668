import React from "react";
import LocalLoan from "../components/LocalLoan";
import AutoLogout from "./AutoLogout";

export default function LocalLoanAutoLogout() {
  return (
    <AutoLogout>
      <LocalLoan />
    </AutoLogout>
  );
}
