import React, { useState } from "react";
import SidbarComponent from "./SidbarComponent";
import TopBarComponent from "./TopBarComponent";
import { FooterComponents } from "./FooterComponent";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SelectionComponent() {
  const [completionYear, setCompletionYear] = useState("");
  const [studyArea, setStudyArea] = useState("");
  const [academicYears, setAcademicYears] = useState([]);
  const [studyAreas, setStudyAreas] = useState([]);

  // const [tokensettings, setTokenSettings] = useState(
  //   JSON.parse(sessionStorage.getItem("user"))
  // );

  const token = JSON.parse(sessionStorage.getItem("user"));

  const selectionTriger = async () => {
    if (completionYear && studyArea) {
      await axios
        .get(
          process.env.REACT_APP_BASE_URL +
            `hec/settings/initiate?completionYearCode=${completionYear}&categoryCode=${studyArea}&key=${process.env.REACT_APP_KEY}`,
          {
            headers: {
              Authorization: "Bearer " + token["message"],
            },
          }
        )
        .then((response) => {
          //save data in session storage
          toast.success("Selection initiation is done", {
            position: "top-center",
            autoClose: 10000,
            theme: "colored",
          });
        })
        .catch((error) => {
          if (error.response.status === 409) {
            toast.warning("No settings were found", {
              position: "top-center",
              autoClose: 15000,
              theme: "colored",
            });
          } else {
            toast.error(
              "No local loans found for the given year and category",
              {
                position: "top-center",
                autoClose: 15000,
                theme: "colored",
              }
            );
          }
        });
    } else {
      toast.warning("Please, provide all neccessary information", {
        position: "top-center",
        autoClose: 15000,
        theme: "colored",
      });
    }
  };

  const fetchAcademicYear = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "hec/academic/getAcademicYears"
      );
      const academicYearList = await response.data;
      setAcademicYears(academicYearList);
      return academicYearList;
    } catch {
      return null;
    }
  };

  useQuery({
    queryKey: ["academicYearKey"],
    queryFn: () => fetchAcademicYear(),
    retry: 6,
  });

  const fetchStudyAreas = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "hec/studyArea/getStudyAreas"
      );
      const studyAreasList = await response.data;
      setStudyAreas(studyAreasList);
      return studyAreasList;
    } catch {
      return null;
    }
  };

  useQuery({
    queryKey: ["areasKey"],
    queryFn: () => fetchStudyAreas(),
    retry: 6,
  });

  return (
    <div className="bg-slate-100">
      <TopBarComponent />
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar"
      >
        <SidbarComponent />
      </aside>

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-white border-b rounded-lg dark:border-gray-700 mt-14">
          <div className="flex items-center justify-center h-12 mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <h1 className="lg:text-5xl md:text-sm font-extrabold">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-slate-500 to-slate-300">
                SELECTION INITIATION
              </span>
            </h1>
            <p className="text-2xl text-gray-400 dark:text-gray-500"></p>
          </div>
          {/* <div className="grid grid-cols-3 gap-4 mb-4"> */}

          <div className="flex flex-row">
            <div className="mb-20 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
              <div className="mt-[10vh] xl:ml-[20vh] sm:ml-[1vh] md:mr-[20vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-black">
                  Selection Criteria
                </h4>
                {/* User Email */}
                <div className="mb-6">
                  <label
                    htmlFor="userEmail"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                  >
                    CompletionYear
                  </label>
                  <select
                    id="countries"
                    onChange={(e) => setCompletionYear(e.target.value)}
                    value={completionYear}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>Select completion year</option>
                    {academicYears &&
                      academicYears.map((academicYear) => (
                        <option
                          key={academicYear.id}
                          value={academicYear.academicYearName}
                        >
                          {academicYear.academicYearName}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="userPassword"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-black"
                  >
                    Study Area
                  </label>
                  <select
                    id="countries"
                    onChange={(e) => {
                      setStudyArea(e.target.value);
                    }}
                    value={studyArea}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-200 dark:text-black dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option defaultValue>Select Study area</option>
                    {studyAreas &&
                      studyAreas.map((study) => (
                        <option key={study.id}>{study.studyAreaName}</option>
                      ))}
                  </select>
                </div>

                <button
                  className="linear mt-2 w-full rounded-xl bg-cyan-800	 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  onClick={() => selectionTriger()}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="grid gap-4">
            <FooterComponents />
          </div>
        </div>
      </div>
    </div>
  );
}
