import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import SignIn from "./components/SignIn";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
//import LocalLoan from "./components/LocalLoan";
//import Dashboard from "./components/Dashboard";
//import BursaryApplication from "./components/BursaryApplication";
import BursaryAutLogout from "./LogoutTimeOut/BursaryAutLogout";
import DashboardAutoLogout from "./LogoutTimeOut/DashboardAutoLogout";
import LocalLoanAutoLogout from "./LogoutTimeOut/LocalLoanAutoLogout";
import SelectionComponent from "./components/SelectionComponent";
import LocalLoanApproveStep from "./components/LocalLoanApproveStep";
import LoanReport from "./components/LoanReport";

function App() {
  const queryClient = new QueryClient(); // Instance of QueryClient

  //authentication

  const AuthenticationRouter = () => {
    const getAuthentication = sessionStorage.getItem("user");
    let auth = { token: getAuthentication };
    return auth.token ? <Outlet /> : <Navigate to="/" />;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route element={<AuthenticationRouter />}>
          <Route path="/localLoan" element={<LocalLoanAutoLogout />} />
          <Route path="/busaryapplication" element={<BursaryAutLogout />} />
          <Route path="/selection" element={<SelectionComponent />} />
          <Route path="/loanApprovalStep" element={<LocalLoanApproveStep />} />
          <Route path="/dashboard" element={<DashboardAutoLogout />} />
          {/* <Route path="/LoanReport" element={<LoanReport />} /> */}
        </Route>
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
