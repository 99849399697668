import React from "react";
import Dashboard from "../components/Dashboard";
import AutoLogout from "./AutoLogout";

export default function DashboardAutoLogout() {
  return (
    <AutoLogout>
      <Dashboard />
    </AutoLogout>
  );
}
