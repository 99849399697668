import React from "react";
import hecLogin from "../assets/img/loginhec.png";
import { FooterComponents } from "./FooterComponent";

export default function LogoComponent() {
  return (
    <div>
      <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[25vw] 2xl:w-[25vw]">
        <div
          className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
          style={{ backgroundImage: `url(${hecLogin})` }}
        >
          <FooterComponents login={"true"} />
        </div>
      </div>
    </div>
  );
}
