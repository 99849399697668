import React, { useState } from "react";

import { Button, Table } from "flowbite-react";
import SidbarComponent from "./SidbarComponent";
import TopBarComponent from "./TopBarComponent";
import { FooterComponents } from "./FooterComponent";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Dropdown } from "flowbite-react";
import { useNavigate } from "react-router-dom";

export default function LocalLoanApproveStep() {
  const [tokensettings, setTokenSettings] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  //const token = JSON.parse(sessionStorage.getItem("user"));
  const [selectionSettings, setSelectionSettings] = useState([]);

  const nav = useNavigate();

  const getSettings = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "hec/settings/getSettings",
        {
          headers: {
            Authorization: "Bearer " + tokensettings["message"],
          },
        }
      );
      const settings = await response.data;
      setSelectionSettings(settings);
      return selectionSettings;
    } catch (error) {
      toast.info("settings not found", {
        position: "top-center",
        autoClose: 10000,
        theme: "colored",
      });
    }
  };

  const updateStatus = async (status, settingsId) => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          `hec/settings/updateSettings?settingID=${settingsId}&status=${status}`,
        {
          headers: {
            Authorization: "Bearer " + tokensettings["message"],
          },
        }
      )
      .then((response) => {
        //save data in session storage
        toast.success("Setting updated to " + status + " .", {
          position: "top-center",
          autoClose: 10000,
          theme: "colored",
        });
        window.location.reload(false);
      })
      .catch((error) => {
        toast.error("Settings not updated", {
          position: "top-center",
          autoClose: 15000,
          theme: "colored",
        });
      });
  };

  const navigatingToLoan = (sections, academicYeear) => {
    const categories = {
      section: sections,
      academicYear: academicYeear,
    };

    sessionStorage.setItem("categories", JSON.stringify(categories));
  };

  const { isPending, error, data } = useQuery({
    queryKey: ["settingsID"],
    queryFn: () => getSettings(),
    retry: 6,
    staleTime: 10000,
  });

  return (
    <div className="bg-slate-100">
      <TopBarComponent />
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar"
      >
        <SidbarComponent />
      </aside>

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-white border-b rounded-lg dark:border-gray-700 mt-14">
          <div className="flex items-center justify-center h-28 mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <h1 className="lg:text-5xl md:text-sm font-extrabold">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-slate-500 to-slate-300">
                SELECTION APPROVAL LEVELS
              </span>
            </h1>
            <p className="text-2xl text-gray-400 dark:text-gray-500"></p>
          </div>
          <div className="overflow-x-auto h-96">
            {isPending ? (
              <div
                role="status"
                className="space-y-2.5 animate-pulse max-w-lg h-full w-full ml-80 mt-16"
              >
                <div className="flex items-center w-full">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[480px]">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                </div>
                <div className="flex items-center w-full max-w-[400px]">
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[480px]">
                  <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                </div>
                <div className="flex items-center w-full max-w-[440px]">
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[360px]">
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            ) : tokensettings["role"]["roleName"] === "Admin" ||
              tokensettings["role"]["roleName"] === "DG" ? (
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>Qualification Area</Table.HeadCell>
                  <Table.HeadCell>Number of students </Table.HeadCell>
                  <Table.HeadCell>Living Allowance/Student </Table.HeadCell>
                  <Table.HeadCell>Total Living Allowance </Table.HeadCell>
                  <Table.HeadCell>Tuition Fees/Student </Table.HeadCell>
                  <Table.HeadCell>Total Tuition Fees </Table.HeadCell>
                  <Table.HeadCell>Academic Year</Table.HeadCell>
                  <Table.HeadCell>Status</Table.HeadCell>
                  <Table.HeadCell>Action</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {selectionSettings.map((settings) => (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={settings.id}
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {settings.studentCategory}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {settings.selectedNumber}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {settings.livingAllowancePerStudent}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {settings.totalLivingAllowance}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {settings.tuitionFeePerStudent}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {settings.totalTuitionFees}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {settings["academicYear"].academicYearName}
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-semibold text-blue-600 dark:text-slate-800">
                        {settings.status}
                      </Table.Cell>
                      <Table.Cell>
                        <Dropdown label="Manage" dismissOnClick={false}>
                          {tokensettings["role"]["roleName"] === "Admin" ||
                          tokensettings["role"]["roleName"] === "DG" ? (
                            <>
                              <Dropdown.Item>
                                <a
                                  className="h-9 items-center"
                                  color="purple"
                                  onClick={() => {
                                    navigatingToLoan(
                                      settings.studentCategory,
                                      settings["academicYear"].academicYearName
                                    );
                                    nav("/localLoan");
                                  }}
                                >
                                  STUDENT LIST
                                </a>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  if (settings.status !== "APPROVED") {
                                    updateStatus("verify", settings.id);
                                  }
                                }}
                              >
                                {settings.status !== "APPROVED" ? "VERIFY" : ""}
                              </Dropdown.Item>
                            </>
                          ) : (
                            <Dropdown.Item></Dropdown.Item>
                          )}

                          {(tokensettings["role"]["roleName"] === "DG" &&
                            settings.status === "VERIFIED") ||
                          settings.status === "APPROVED" ? (
                            <>
                              <Dropdown.Item
                                onClick={() =>
                                  updateStatus("approve", settings.id)
                                }
                              >
                                APPROVE
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  updateStatus("return", settings.id)
                                }
                              >
                                RETURN
                              </Dropdown.Item>
                            </>
                          ) : (
                            <Dropdown.Item></Dropdown.Item>
                          )}
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div>
          <FooterComponents />
        </div>
      </div>
    </div>
  );
}
