import React, { useState } from "react";

import { Pagination, Table } from "flowbite-react";
import SidbarComponent from "./SidbarComponent";
import TopBarComponent from "./TopBarComponent";
import { FooterComponents } from "./FooterComponent";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function BursaryApplication() {
  const [busaryApplications, setBusaryApplications] = useState([]);

  const [totalPage, setTotalPage] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const token = JSON.parse(sessionStorage.getItem("user"));

  const fetchbusaryApplications = async (page = 1) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL +
          "hec/busaryApplication/getAllApplications",
        {
          headers: {
            Authorization: "Bearer " + token["message"],
          },
          params: {
            page: page - 1,
          },
        }
      );
      const busaryApplications = await response.data;
      setBusaryApplications(busaryApplications["content"]);
      setTotalPage(busaryApplications["totalPages"]);
      return busaryApplications;
    } catch (error) {
      toast.info("The Busary applications are not found", {
        position: "top-center",
        autoClose: 10000,
        theme: "colored",
      });
    }
  };

  const onPageChange = (page) => {
    fetchbusaryApplications(page);
    setCurrentPage(page);
  };

  const { isPending, error, data } = useQuery({
    queryKey: ["bursaryApplicationId"],
    queryFn: () => fetchbusaryApplications(),
    retry: 6,
    staleTime: 10000,
  });

  return (
    <div className="bg-slate-100">
      <TopBarComponent />
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar"
      >
        <SidbarComponent />
      </aside>

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-white border-b rounded-lg dark:border-gray-700 mt-14">
          <div className="bg-white pb-4 dark:bg-gray-900">
            <div className="relative pt-3 pl-3">
              <div className="rtl:inset-r-0 pointer-events-none absolute inset-y-0 flex items-center ps-3 start-0 mt-2 ml-2">
                <svg
                  className="h-4 w-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="block w-80 rounded-lg border border-gray-300 bg-gray-50 pt-2 text-sm text-gray-900 ps-10 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder="Search the candidates"
              />
            </div>
          </div>
          <div className="overflow-x-auto h-96">
            {isPending && token["role"]["roleName"] === "Admin" ? (
              <div
                role="status"
                className="space-y-2.5 animate-pulse max-w-lg h-full w-full ml-80 mt-16"
              >
                <div className="flex items-center w-full">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[480px]">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                </div>
                <div className="flex items-center w-full max-w-[400px]">
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[480px]">
                  <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                </div>
                <div className="flex items-center w-full max-w-[440px]">
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                </div>
                <div className="flex items-center w-full max-w-[360px]">
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                  <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
                  <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            ) : token["role"]["roleName"] === "Admin" ? (
              <div>
                <Table striped>
                  <Table.Head>
                    <Table.HeadCell>Reg Number</Table.HeadCell>
                    <Table.HeadCell>First Name</Table.HeadCell>
                    <Table.HeadCell>Last Name</Table.HeadCell>
                    <Table.HeadCell>Gender</Table.HeadCell>
                    <Table.HeadCell>Index Number</Table.HeadCell>
                    <Table.HeadCell>Senior Six Aggregate</Table.HeadCell>
                    <Table.HeadCell>Qualification</Table.HeadCell>
                    <Table.HeadCell> Academic Year</Table.HeadCell>
                    <Table.HeadCell>Area</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {busaryApplications.map((data) => (
                      <Table.Row
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        key={data.registrationNumber}
                      >
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {data.registrationNumber}
                        </Table.Cell>
                        <Table.Cell>{data.firstName}</Table.Cell>
                        <Table.Cell>{data.lastName}</Table.Cell>
                        <Table.Cell>{data.gender}</Table.Cell>
                        <Table.Cell>{data.indexNumber}</Table.Cell>
                        <Table.Cell>{data.s6Aggregate}</Table.Cell>
                        <Table.Cell>
                          {data["qualificationId"].qualificationName}
                        </Table.Cell>
                        <Table.Cell>{data.completionYearCode}</Table.Cell>
                        <Table.Cell>
                          {data["qualificationId"]["studyAreaId"].studyAreaName}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <div className="flex overflow-x-auto sm:justify-center">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPage}
                    onPageChange={onPageChange}
                    showIcons
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div>
          <FooterComponents />
        </div>
      </div>
    </div>
  );
}
