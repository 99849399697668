import React from "react";
import SidbarComponent from "./SidbarComponent";
import TopBarComponent from "./TopBarComponent";
import { FooterComponents } from "./FooterComponent";

export default function Dashboard() {
  return (
    <div className="bg-slate-100">
      <TopBarComponent />
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar"
      >
        <SidbarComponent />
      </aside>

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-white border-b rounded-lg dark:border-gray-700 mt-14">
          <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <h1 className="lg:text-5xl md:text-sm font-extrabold">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-slate-500 to-slate-300">
                HEC Automated Business Processes
              </span>
            </h1>
            <p className="text-2xl text-gray-400 dark:text-gray-500"></p>
          </div>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="flex items-center justify-center  rounded bg-gray-50 dark:bg-gray-800">
              <p className="text-sm text-gray-400 dark:text-gray-500 p-7">
                The Higher Education Council is a council established as an
                independent government agency to lead Rwanda towards a future as
                a knowledge-based economy by promoting professional development
                and research.
              </p>
            </div>
            <div className="flex items-center justify-center  rounded bg-gray-50 dark:bg-gray-800">
              <p className="text-sm text-gray-400 dark:text-gray-500 p-7">
                The student: The student can view their chances of obtaining a
                scholarship based on their score in the national exams, the
                required score for the field they wish to study in etc. The
                student must enter their registration number to access his/her
                results.
              </p>
            </div>
            <div className="flex items-center justify-center  rounded bg-gray-50 dark:bg-gray-800">
              <p className="text-sm text-gray-400 dark:text-gray-500 p-7">
                The HLI applicant: This user is created to apply for an
                Accreditation permit. The HLI applicant is most often the
                director of the institution. This user is in charge of adding
                new information on their university.
              </p>
            </div>
          </div>
          <div className="grid gap-4">
            <FooterComponents />
          </div>
        </div>
      </div>
    </div>
  );
}
