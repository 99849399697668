import React from "react";
import logo from "../assets/img/heclogo.png";
import { Avatar, Button, Dropdown, Navbar } from "flowbite-react";
import { useNavigate } from "react-router-dom";

export default function TopBarComponent() {
  const nav = useNavigate();

  const logout = () => {
    sessionStorage.clear();
    nav("/");
  };

  const token = JSON.parse(sessionStorage.getItem("user"));

  return (
    <div className="fixed top-0 z-50 w-full  border-2 border-gray-200 border-b rounded-lg dark:bg-gray-800 dark:border-gray-700">
      <Navbar fluid rounded>
        <Navbar.Brand href="/dashboard">
          <img src={logo} className="mr-3 h-6 sm:h-9" alt="HEC MIS" />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            HEC MIS
          </span>
        </Navbar.Brand>
        <div className="flex md:order-2">
          <Dropdown
            arrowIcon={false}
            inline
            label={<Avatar alt="User settings" img={logo} rounded />}
          >
            <Dropdown.Header>
              <span className="block text-sm">HEC MIS</span>
              <span className="block truncate text-sm font-medium">
                hec@info.com
              </span>
            </Dropdown.Header>
            <Dropdown.Item>Dashboard</Dropdown.Item>
            <Dropdown.Item>Settings</Dropdown.Item>
            {/* <Dropdown.Item>Local Loan</Dropdown.Item> */}
            <Dropdown.Divider />
            <Dropdown.Item>
              <Button onClick={logout}>Sign Out</Button>
            </Dropdown.Item>
          </Dropdown>
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse>
          <Navbar.Link href="#" active>
            Home
          </Navbar.Link>
          {token["role"]["roleName"] === "Admin" ? (
            <>
              <Navbar.Link href="/loanApprovalStep">
                Loan Verification
              </Navbar.Link>
              <Navbar.Link href="/busaryapplication">
                Admission List
              </Navbar.Link>
            </>
          ) : (
            <div></div>
          )}
          <Navbar.Link href="/dashboard">Dashboard</Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
